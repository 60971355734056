import { Document, Font } from "@react-pdf/renderer";
import KaiTi from "fonts/KaiTi.ttf";
import FuturaBkBT from "fonts/FuturaBkBT.ttf";
import FuturaMediumBT from "fonts/FuturaMediumBT.ttf";
import FuturaLtBT from "fonts/FuturaLtBT.ttf";
import TheOnlyException from "fonts/TheOnlyException.ttf";

import { PDFInfoType } from "./Preview/constant";
import { styles } from "./Templates/constant";

import FrontPage from "./Templates/FrontPage";
import IntroPage from "./Templates/IntroPage";
import ConcludingRemarkPage from "./Templates/ConcludingRemarkPage";
import LearningHighlightPage from "./Templates/LearningHighlightPage";
import DevelopmentalGoalsAndMilestonesPage from "./Templates/DevelopmentalGoalsAndMilestonesPage";
import ModulePages from "./Templates/LittleModule/ModulePages";
import DevelopmentalGoalPage from "./Templates/DevelopmentalGoalPage";
import {
  HEAD_TEXT,
  HEART_TEXT,
  HANDS_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";
import { API_Fundamental } from "types/project";
import LearningStoryPages from "./Templates/LearningStories/LearningStoryPages";

//Register Font
Font.register({
  family: "Jost",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "medium",
    },
  ],
});

Font.register({
  family: "FuturaLtBT",
  fonts: [
    {
      src: FuturaLtBT,
    },
  ],
});

Font.register({
  family: "FuturaMediumBT",
  fonts: [
    {
      src: FuturaMediumBT,
    },
  ],
});

Font.register({
  family: "FuturaBkBT",
  fonts: [
    {
      src: FuturaBkBT,
    },
  ],
});

Font.register({
  family: "TheOnlyException",
  src: TheOnlyException,
});

Font.register({
  family: "KaiTi",
  src: KaiTi,
});

const ChildrenPortfolioPDF = ({
  PDFInfo,
  currentLanguage,
  developmentalGoals,
}: {
  PDFInfo: PDFInfoType;
  currentLanguage: string;
  developmentalGoals: API_Fundamental[];
}) => {
  const isChinese = currentLanguage === "zh";
  developmentalGoals = developmentalGoals.sort(
    (a: any, b: any) => a.priority - b.priority
  );

  developmentalGoals.sort((a, b) => {
    const order = ["heart", "head", "hands"];
    const tagDifference = order.indexOf(a.tag) - order.indexOf(b.tag);
    if (tagDifference === 0) {
      return a.priority - b.priority;
    }
    return tagDifference;
  });

  if (isChinese) {
    styles.body = { ...styles.body, ...styles.chineseFont };
  } else {
    styles.body = { ...styles.body, ...styles.englishFont };
  }

  Font.registerHyphenationCallback((word: string) => {
    if (word.length === 1 || !isChinese) {
      return [word];
    }

    // Break chinese text
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  });

  return (
    <Document title={PDFInfo.profileName} language={currentLanguage}>
      <FrontPage PDFInfo={PDFInfo} />

      <IntroPage PDFInfo={PDFInfo} />

      <LearningHighlightPage PDFInfo={PDFInfo} />

      {/* <AccomplishedPage PDFInfo={PDFInfo} isLittleProject={true} />

      <AccomplishedPage PDFInfo={PDFInfo} isLittleProject={false} /> */}

      {/* {PDFInfo.childImages.length > 0 && <PhotoGalleryPage PDFInfo={PDFInfo} />}

      {PDFInfo.completedWorkImages.length > 0 && (
        <PhotoGalleryPage PDFInfo={PDFInfo} isCompletedWork={true} />
      )} */}

      <ModulePages PDFInfo={PDFInfo} />
      
      <LearningStoryPages PDFInfo={PDFInfo} />

      <DevelopmentalGoalsAndMilestonesPage />

      <DevelopmentalGoalPage
        PDFInfo={PDFInfo}
        tag={HEART_TEXT}
      />

      <DevelopmentalGoalPage
        PDFInfo={PDFInfo}
        tag={HEAD_TEXT}
      />

      <DevelopmentalGoalPage
        PDFInfo={PDFInfo}
        tag={HANDS_TEXT}
      />

      <ConcludingRemarkPage PDFInfo={PDFInfo} />
    </Document>
  );
};

export default ChildrenPortfolioPDF;
