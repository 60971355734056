import { Box, Typography } from "@mui/material";
import Image from "components/Image/Image";
import { API_Child } from "types/portfolio";
import ImageFrame from "images/children-portfolio-v2/image-frame-2.png";
import { checkIsChineseChar } from "helper/helper";
import { truncateText } from "../../Templates/DevelopmentalGoalComponents/helper";
interface IndividualObservationProps {
  observation: API_Child.PortfolioObservation;
  scale: number;
}

const IndividualObservation = ({
  observation,
  scale,
}: IndividualObservationProps) => {
  let caption;
  if (checkIsChineseChar(observation.caption)) {
    caption = truncateText(observation.caption, 3, 30);
  } else {
    caption = truncateText(observation.caption, 3, 120);
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: `${8 * scale}px`,
        position: "relative",
        height: `${150 * scale}px`,
        width: `${120 * scale}px`,
        boxSizing: "border-box",
      }}
    >
      <Image
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "125%",
          height: "100%",
          objectFit: "fill",
          zIndex: 2,
        }}
        src={ImageFrame}
        alt="Frame"
      />
      {observation.image && (
        <Image
          style={{
            position: "absolute",
            top: "10%",
            left: "16%",
            width: "94%",
            height: "62%",
            objectFit: "cover",
            zIndex: 1,
          }}
          src={observation.localImageUrl}
          alt={observation.caption}
        />
      )}
      <Typography
        sx={{
          position: "absolute",
          bottom: "8%",
          left: "23%",
          width: "80%",
          fontSize: `${6 * scale}px`,
          fontFamily: checkIsChineseChar(caption) ? "KaiTi" : "FuturaLtBT",
          color: "#000",
          textAlign: "center",
          lineHeight: 1.5,
          overflow: "hidden",
        }}
      >
        {caption}
      </Typography>
    </Box>
  );
};

export default IndividualObservation;
